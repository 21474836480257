@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

body {
  font-family: "Quicksand", sans-serif;
  color: var(--theme-text-color);
}
.page_heading {
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 700;
}
.page_heading_h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 128px;
}
.heading_span {
  color: var(--theme-primary-color);
}
p {
  color: var(--theme-text-color);
  line-height: 30px;
  font-weight: 500;
}
.common_bottom_margin {
  margin-bottom: 60px;
}
.common_btn {
  font-weight: 700;
  border-radius: 70px;
  padding: 10px 30px;
  color: #ffffff;
  border: 1px solid var(--theme-secondary-color);
  background-color: var(--theme-secondary-color);
}
