/* ----- Header ----- */

.header_page .header_content {
  padding: 10px 10px 10px 30px;
  border-radius: 70px;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.header_page .header_content p {
  margin-bottom: 0;
  font-weight: 700;
}
.header_page .top_content p {
  margin-bottom: 0;
}

/* ----- Banner ----- */

.banner_page {
  margin-bottom: 500px !important;
}
.banner_page .banner_main_content {
  text-align: center;
  height: 80vh;
  position: relative;
  border-bottom: 1px solid var(--theme-primary-background-color);
  border-radius: 0px 0px 150px 150px;
  box-shadow: #00c9cc2e 0px 25px 20px -20px;
}
.banner_page .banner_main_content .banner_bg_gif_img {
  opacity: 0.2;
}
.banner_page .banner_main_content .banner_content {
  width: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.banner_page .banner_bottom_img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 500px;
  transform: translate(-50%, 35%);
}

/* ----- Features ----- */

.features_page .feature_content {
  background-image: url("../../Assets/Images/features.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 400px;
}
.features_page .feature_card {
  padding: 15px;
  border-radius: 20px;
  border: 1px solid var(--theme-primary-background-color);
}
.features_page .feature_card .feature_card_heading {
  font-weight: 700;
}

/* ----- Status ----- */

.status_page .status_right_content {
  position: relative;
}
.status_page .status_back_img {
  position: absolute;
  top: 50px;
  z-index: -9999;
}

/* ----- Smart AI ----- */

.smart_ai_page .smart_ai_content {
  text-align: center;
}
.smart_ai_page .card_col {
  display: flex;
  flex-direction: column;
}
.smart_ai_page .smart_ai_card {
  border: 1px solid var(--theme-primary-background-color);
  padding: 20px 20px 20px 60px;
  border-radius: 20px;
  position: relative;
}
.smart_ai_page .smart_ai_card .smart_ai_card_content .smart_ai_card_heading {
  font-weight: 700;
}
.smart_ai_page .smart_ai_card .smart_ai_card_content .smart_ai_card_text {
  font-weight: 500;
}
.smart_ai_card .smart_ai_icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-46%, -50%);
}
.smart_ai_card .smart_ai_icon img {
  height: 112px;
}

/* ----- Footer ----- */

.footer_page {
  background-color: #f8f8f8;
}
.footer_page .footer_ul {
  flex-direction: column;
  display: flex;
  align-items: center;
}
.footer_page .footer_ul h4 {
  margin-bottom: 25px;
  font-weight: 700;
}
.footer_page .footer_ul p {
  font-weight: 700;
}
.footer_page .footer_bottom_div {
  display: flex;
  background-color: #c8c8c8;
  justify-content: end;
  padding: 10px;
}
.footer_page .footer_bottom_div p {
  margin-bottom: 0;
  font-weight: 700;
  padding: 0px 20px;
}
